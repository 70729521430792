import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3572675178/src/src/templates/docs/index.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className="row">
  <div className="column">
    <a href="/stream/getting-started" className="content" data-path="/getting-started">
      <img src="https://docs.fidel.uk/assets/images/svgs/get-started-icon.svg" />
      <h2 data-no-link>Get Started</h2>
      <h3>Start building card-linked applications with our quickstart guide</h3>
    </a>
  </div>
  <div className="column">
    <a href="https://dashboard.fidel.uk/playground" className="content">
      <img src="https://docs.fidel.uk/assets/images/svgs/playground-icon.svg" />
      <h2 data-no-link>API Playground</h2>
      <h3>Test API requests in real-time and see how we format the returned data.</h3>
    </a>
  </div>
    </div>
    <div className="row">
  <div className="column">
    <a href="/stream/tutorials/card-linking" className="content">
      <img src="https://docs.fidel.uk/assets/images/svgs/tutorials-icon.svg" />
      <h2 data-no-link>Tutorials</h2>
      <h3>Learn how to build a card-linking feature into your applications.</h3>
    </a>
  </div>
  <div className="column">
    <a href="/stream/sdks/web/v3" data-path="/stream/sdks/web/v3" className="content">
      <img src="https://docs.fidel.uk/assets/images/svgs/sdks-icon.svg" />
      <h2 data-no-link>SDKs</h2>
      <h3>Take the easy and secure way to add card enrollment capabilities into your application.</h3>
    </a>
  </div>
    </div>
    <h2>{`Introduction`}</h2>
    <p>{`Fidel API provides a developer-friendly, secure and reliable API to link payment cards to mobile and web applications. Through a single API, developers can securely access real-time data from the card networks and build applications on top of a powerful financial infrastructure platform.`}</p>
    <p>{`Once a card is enrolled and verified, every payment event (i.e. authorization, clearing, refund) from that card will be sent to your server in real time. The transaction data you receive is of high quality, accuracy and consistency. This allows you to build a rich experience for cardholders at the moment the transaction is made, while they are still at the terminal or checkout page.`}</p>
    <h2>{`Transaction Life Cycle`}</h2>
    <p>{`To understand how the Transaction Stream API works and the data it provides to you, it’s important to understand the authorization processes and fund movements that happen when a cardholder makes a purchase.`}</p>
    <h3>{`Authorization, clearing`}</h3>
    <p>{`When a cardholder uses a credit or debit card to make a purchase, the funds are not immediately transferred to the merchant’s account. There are two important events that need to happen for the funds to be transferred: authorization and clearing. Here’s how these events occur:`}</p>
    <ol>
      <li parentName="ol">{`When the cardholder initiates the transaction, their bank (issuing bank, issuer) needs to authorize it. For this, the `}<strong parentName="li">{`authorization request`}</strong>{` must travel from the merchant through the merchant’s bank (acquirer) and through the card network to the issuing bank.`}<br parentName="li"></br>
        <img parentName="li" {...{
          "src": "https://docs.fidel.uk/assets/images/trx-life-cycle-1.png",
          "alt": null
        }}></img></li>
      <li parentName="ol">{`If the cardholder has the necessary funds, the issuing bank sends back on the same path the `}<strong parentName="li">{`authorization response`}</strong>{` containing the authorization code (auth code), which means that the cardholder can make the purchase.`}<br parentName="li"></br>
        {`At this point, the payment amount is still on the cardholder’s account. However, the merchant can safely provide the purchased goods or services, as the transaction was authorized. Usually, the merchant places an authorization hold on the cardholder’s account for the authorized amount of the sale.`}<br parentName="li"></br>
        <img parentName="li" {...{
          "src": "https://docs.fidel.uk/assets/images/trx-life-cycle-2.png",
          "alt": null
        }}></img></li>
      <li parentName="ol">{`The next step is the `}<strong parentName="li">{`clearing request`}</strong>{`, which initiates the administrative process of the payment.`}<br parentName="li"></br>
        {`Typically, clearing occurs at the end of the day, when the acquirer bank collects all the transaction information (amounts, auth codes, etc.) from all payment endpoints of the merchant. Then, on its own processing schedule, the acquirer starts processing the payments with the respective issuing banks.`}<br parentName="li"></br>
        <img parentName="li" {...{
          "src": "https://docs.fidel.uk/assets/images/trx-life-cycle-3.png",
          "alt": null
        }}></img></li>
      <li parentName="ol">{`The issuing bank sends back the `}<strong parentName="li">{`clearing response`}</strong>{`, and the funds are moved to the merchant’s account.`}<br parentName="li"></br>
        <img parentName="li" {...{
          "src": "https://docs.fidel.uk/assets/images/trx-life-cycle-4.png",
          "alt": null
        }}></img></li>
    </ol>
    <p>{`Note that at Fidel API both cleared and settled transactions are referred to as cleared transactions.`}</p>
    <h3>{`Where does Fidel API enter the picture?`}</h3>
    <p>{`As we saw, all the transaction events go through the card networks. This is where Fidel API comes into play.`}</p>
    <p>{`When a cardholder links a card to a Fidel API program, Fidel API verifies the card with the associated card network and creates a token to represent that card, thereby not storing sensitive information about the cardholder or card itself. Using this token-based identification, after you verify that the user has access to the card, the card network starts sending the transactions made on that card to Fidel API.`}</p>
    <p>{`You can then retrieve the collected data using the Transaction Stream API. You can also register webhooks to be notified about the card’s transaction events (new authorization, clearing, etc.).`}</p>
    <p><img parentName="p" {...{
        "src": "https://docs.fidel.uk/assets/images/trx-life-cycle-5.png",
        "alt": null
      }}></img></p>
    <h2>{`Collected Data`}</h2>
    <p>{`The Transaction Stream API collects the following data for the supported transactions made with linked cards:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Field name`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Description`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Type`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`accountId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique identifier of the user account at Fidel API.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`amount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The amount of the transaction in the currency it was charged in. In the case of international transactions, the transaction has both `}<inlineCode parentName="td">{`amount`}</inlineCode>{` and `}<inlineCode parentName="td">{`billingAmount`}</inlineCode>{` fields filled in and their value may differ due to the conversion.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`auth`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Indicates whether the transaction was authorized by the issuing bank.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`authCode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique code that authorizes a transaction, sent by the issuer/the PCN on the issuer's behalf when approving a transaction. It mirrors the `}<inlineCode parentName="td">{`identifiers.visaAuthCode`}</inlineCode>{` property. You can use the authorization code in combination with the card number to match an authorization event and a clearing event when the transaction ID is different.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string (or null)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`billingAmount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The amount in the currency of the country of issuance, converted from the original purchase currency. In the case of international transactions, the transaction has both `}<inlineCode parentName="td">{`amount`}</inlineCode>{` and `}<inlineCode parentName="td">{`billingAmount`}</inlineCode>{` fields filled in and their value may differ due to the conversion.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number (or null)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`billingCurrency`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The currency of the country of issuance.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string in ISO 4217 format (or null)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`brand.name`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The name of the merchant where the purchase was made.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`card.firstNumbers`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The first numbers of the card, used for helping users identify their cards.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`card.id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The tokenized card identifier used by Fidel API.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`card.lastNumbers`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The last numbers of the card, used for helping users identify their cards.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`card.scheme`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The payment network (Visa).`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`cardPresent`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Not in use`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`null`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`cleared`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Indicates whether the transaction was cleared.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`created`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The timestamp in UTC format when the transaction object was created in the Fidel API database.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string, timestamp in UTC format`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`currency`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The original currency of the purchase.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string in `}<a parentName="td" {...{
              "href": "https://en.wikipedia.org/wiki/ISO_4217"
            }}>{`ISO 4217`}</a>{` format`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`datetime`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The timestamp (in the local timezone of the merchant/acquirer) of the transaction.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string, timestamp in UTC format`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique identifier of the transaction.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`identifiers.amexApprovalCode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Not in use in Transaction Stream programs.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`null`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`identifiers.mastercardAuthCode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Not in use in Transaction Stream programs.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`null`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`identifiers.mastercardRefNumber`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Not in use in Transaction Stream programs.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`null`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`identifiers.mastercardTransactionSequenceNumber`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Not in use in Transaction Stream programs.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`null`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`identifiers.visaAuthCode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique code that is sent along with the authorization of the transaction by Visa. It mirrors the `}<inlineCode parentName="td">{`authCode`}</inlineCode>{` property.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string (or null)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`location`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Details of the merchant where the purchase was made:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`location.city`}</inlineCode>{`, `}<inlineCode parentName="td">{`location.countryCode`}</inlineCode>{`, `}<inlineCode parentName="td">{`location.postcode`}</inlineCode>{`, `}<inlineCode parentName="td">{`location.state`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string (city, postcode, and state can be null)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`merchantCategoryCode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The merchant category code (MCC) provided by the card network. It is used to classify businesses by the types of goods provided or services rendered.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`programId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The identifier of the Fidel API program that this transaction is linked to.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`programType`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The type of the Fidel API program that this transaction is linked to (`}<inlineCode parentName="td">{`transaction-stream`}</inlineCode>{`).`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`updated`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date and time when this transaction was last time updated (authorized/cleared/reimbursed).`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string, timestamp in UTC format`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`wallet`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Not in use.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`null`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`You can see the JSON format of an example transaction object in the `}<a parentName="p" {...{
        "href": "/stream/transactions"
      }}>{`Transactions`}</a>{` page.`}</p>
    <h2>{`Learn More`}</h2>
    <p>{`Start your Fidel API journey by creating an account. Read the `}<a parentName="p" {...{
        "href": "/stream/getting-started"
      }}>{`Getting Started`}</a>{` page to learn how.`}</p>
    <p>{`You can see an example implementation for integrating the Fidel APIs and Web SDK in our `}<a parentName="p" {...{
        "href": "https://github.com/Enigmatic-Smile/fidel-api-sample-app"
      }}>{`sample application on GitHub`}</a>{`.`}</p>
    <p>{`Check out the `}<a parentName="p" {...{
        "href": "https://transaction-stream.fidel.uk/reference/introduction-1"
      }}>{`API Reference`}</a>{` to see all available requests, code examples and response payloads.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      